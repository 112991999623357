import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import KeyboardInteraction from './KeyboardInteraction';
import Terminal from './Terminal';  // Add this line
import MatrixRain from './MatrixRain';  // Add this line
import AudioPlayer from './AudioPlayer';  // Add this line
function App() {
  const contentRef = useRef(null);
  const backgroundRef = useRef(null);
  const keyboardRef = useRef(null);
  const pawRef = useRef(null);
  const rightPawRef = useRef(null);
  const terminalRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    updatePositions();
  }, [windowSize]);

  const points = [
    { x: 601.5, y: 286.5 },
    { x: 643.5, y: 1028.5 },
    { x: 649.5, y: 1060.5 },
    { x: 1868.5, y: 949.5 },
    { x: 1837.5, y: 213.5 }
  ];

  const keyboardPoints = [
    { x: 988.5, y: 1264.5 },
    { x: 1094.5, y: 1395.5 },
    { x: 1650.5, y: 1359.5 },
    { x: 1728.5, y: 1351.5 },
    { x: 1728.5, y: 1339.61 },
    { x: 1574.5, y: 1223.5 },
    { x: 1073.5, y: 1246.5 },
    { x: 988.5, y: 1255.09 }
  ];

  const originalWidth = 2560;
  const originalHeight = 1440;

  const updatePositions = () => {
    const windowWidth = windowSize.width;
    const windowHeight = windowSize.height;

    const scaleX = windowWidth / originalWidth;
    const scaleY = windowHeight / originalHeight;
    const scale = Math.min(scaleX, scaleY);

    const offsetX = (windowWidth - originalWidth * scale) / 2;
    const offsetY = (windowHeight - originalHeight * scale) / 2;

    if (backgroundRef.current) {
      backgroundRef.current.style.width = `${originalWidth * scale}px`;
      backgroundRef.current.style.height = `${originalHeight * scale}px`;
      backgroundRef.current.style.left = `${offsetX}px`;
      backgroundRef.current.style.top = `${offsetY}px`;
    }

    if (pawRef.current) {
      pawRef.current.style.width = `${originalWidth * scale}px`;
      pawRef.current.style.height = `${originalHeight * scale}px`;
      pawRef.current.style.left = `0px`;
      pawRef.current.style.top = `0px`;
      // Reset the left paw position when window is resized
      pawRef.current.style.transform = 'translate(50px, 0)';
    }

    const minX = Math.min(...points.map(p => p.x));
    const maxX = Math.max(...points.map(p => p.x));
    const minY = Math.min(...points.map(p => p.y));
    const maxY = Math.max(...points.map(p => p.y));

    const width = maxX - minX;
    const height = maxY - minY;

    if (contentRef.current) {
      contentRef.current.style.width = `${width * scale}px`;
      contentRef.current.style.height = `${height * scale}px`;
      contentRef.current.style.left = `${minX * scale + offsetX}px`;
      contentRef.current.style.top = `${minY * scale + offsetY}px`;

      const clipPath = points.map(p =>
        `${(p.x - minX) / width * 100}% ${(p.y - minY) / height * 100}%`
      ).join(', ');
      contentRef.current.style.clipPath = `polygon(${clipPath})`;

      contentRef.current.style.fontSize = `${24 * scale}px`;
    }

    if (keyboardRef.current) {
      keyboardRef.current.style.width = `${originalWidth * scale}px`;
      keyboardRef.current.style.height = `${originalHeight * scale}px`;
      keyboardRef.current.style.left = `${offsetX}px`;
      keyboardRef.current.style.top = `${offsetY}px`;
    }

    if (rightPawRef.current) {
      rightPawRef.current.style.width = `${originalWidth * scale}px`;
      rightPawRef.current.style.height = `${originalHeight * scale}px`;
      rightPawRef.current.style.left = `0px`;
      rightPawRef.current.style.top = `0px`;
      rightPawRef.current.style.transform = 'translate(100px, 0)';
    }
  };

  const updateRightPawPosition = (event) => {
    if (keyboardRef.current && rightPawRef.current) {
      const rect = keyboardRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const moveX = (x / rect.width - 0.5) * 200;
      const moveY = (y / rect.height - 0.5) * 100;

      rightPawRef.current.style.transform = `translate(${moveX + 100}px, ${moveY}px)`;
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateRightPawPosition);
    return () => {
      document.removeEventListener('mousemove', updateRightPawPosition);
    };
  }, []);


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleUserLogin = () => {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.play();
      }
      setIsLoggedIn(true);
    };

    window.addEventListener('userLoggedIn', handleUserLogin);

    return () => {
      window.removeEventListener('userLoggedIn', handleUserLogin);
    };
  }, []);

  if (isMobile) {
    return (
      <div className="mobile-container">
        <div className="background-image"></div>
        <div className="overlay"></div>
        <div className="mobile-message">
          <p>This page is not yet functional for mobile devices</p>
          <div className="social-icons">
            <a href="https://x.com/DevCatCoin" target="_blank" rel="noopener noreferrer">
              <img src="https://t3.ftcdn.net/jpg/06/27/17/06/360_F_627170676_ySfg9ZDrJvtUdjKcoePJuIgeYxr7rJUr.jpg" alt="Twitter/X" className="social-icon" />
            </a>
            {/* <a href="#" target="_blank" rel="noopener noreferrer">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png" alt="Telegram" className="social-icon" />
            </a> */}
            <a href="https://dexscreener.com" target="_blank" rel="noopener noreferrer">
              <img src="https://play-lh.googleusercontent.com/Yk8oT4lKwRdgBzs_SZ0s02vjjwAUMz_8lyKfTGqk0vRZjauY90IzXzg592FDWaNK7Q" alt="Dexscreener" className="social-icon" />
            </a>
          </div>
        </div>
        <MatrixRain width={windowSize.width} height={windowSize.height} />
      </div>
    );
  }

  return (
    <div className="App">
      <AudioPlayer ref={audioPlayerRef} isLoggedIn={isLoggedIn} />
      <div id="background">
        <img ref={backgroundRef} src="bg.png" alt="Background" />
        <MatrixRain width={windowSize.width} height={windowSize.height} />  {/* Add this line */}
      </div>
      <div id="content-container">
        <div id="website-content" ref={contentRef}>
          <Terminal ref={terminalRef} />
        </div>
      </div>
      <div id="keyboard" ref={keyboardRef}>
        <svg width="100%" height="100%" viewBox="0 0 2560 1440" preserveAspectRatio="none">
          <polygon className="keyboard-outline"
            points="988.5,1264.5 1094.5,1395.5 1650.5,1359.5 1728.5,1351.5 1728.5,1339.61 1574.5,1223.5 1073.5,1246.5 988.5,1255.09 988.5,1264.5" />
        </svg>
        <KeyboardInteraction pawRef={pawRef} />
        <img ref={pawRef} src="/LeftPaw.png" alt="Left Paw" className="paw" />
        <img ref={rightPawRef} src="/RightPaw.png" alt="Right Paw" className="right-paw" />
      </div>
    </div>
  );
}

export default App;
